* {
	box-sizing: border-box;
}

body {
	padding: 1rem 0.5rem;
	font-size: 1.3rem;
	line-height: 1.5;
	max-width: 60rem;
	margin: 0 auto;
	color: #333;
}

img {
	max-width: 100%;
	/*animation: App-logo-spin infinite 20s linear;*/
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Header {
	text-align: center;
	border-bottom: 8px double #f9f9f9;
	margin-bottom: 2rem;
	padding-bottom: 1rem;
}

.Strap {
	font-size: 1rem;
}

.MoneyShot {
	position: relative;
}

.MoneyShotImg {
	border: 0px solid #e8cfa9;
	border-radius: 6px;
}

.LogoWrapper {
	display: block;
}

.ImageCaption {
	font-size: 0.75rem;
	position: absolute;
	bottom: 0.5rem;
	right: 1rem;
	padding: 3px;
	background-color: #222;
	color: #e4e4e4;
}

.IntroText {
	font-size: 1.4rem;
	font-style: italic;
}

.emphasis {
	background-image: linear-gradient(
		rgba(0 0 0 / 0) 70%,
		#e5c046 70%
	);
}


.MethodWrapper2 {
	list-style-type: none; 
	padding: 0; 
	margin: 0;
}

.MethodWrapper2 li:nth-child(even) {
	background-color: #dbdbdb;
}


.MethodWrapper2 li:after {
	content: "✼";
	display: block;
	text-align: center;
	margin: 0 0 0 0;
	color: #eee;
}

.MethodWrapper li:after {
	content: "✼";
	display: block;
	text-align: center;
	margin: 0 0 0 0;
	color: #eee;
}


.crimeTable table{
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

.crimeTable td, th {
	border: 1px solid #dddddd;
	text-align: center;
	padding: 8px;
}

.crimeTable th {
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 90%;
	border-bottom: 2px solid #111111;
	border-top: 1px solid #999;
	text-align: center;
}

/*
.crimeTable tr:nth-child(even) {
	background-color: #dddddd;
}
*/


.nav {
	z-index: 1;
	position: fixed;
	top: 50px;
	left: 10px;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 10px 20px;
	box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
	border-radius: 5px;
}

@media screen and (max-width: 899px) {
    .nav {
        left: initial;
        top: initial;
        bottom: 0px;
        width: 100%;
    }
}

.nav a {
    text-decoration: none;
    color: #0008fc;
    text-transform: uppercase;
	font-size: 1.05rem;
    text-align: center;
    font-weight: 600;
}

.nav a:not(:last-child) {
    margin-bottom: 20px;
}




@media screen and (min-width: 900px) {
	body {
		border-left: 4px solid #f9f9f9;
		border-right: 4px solid #f9f9f9;
		padding: 1rem 2rem;
	}

	.IntroWrapper {
		display: table;
		table-layout: fixed;
		width: 100%;
	}


	.MoneyShot,
	.IntroText {
		display: table-cell;
		width: 50%;
		vertical-align: middle;
		text-align: justify;
	}

	.IntroText {
		padding: 1.5rem;
		font-size: 1.5rem;
		text-align: justify;
		position: relative;
	}

	.Ingredients {
		font-size: 1.05rem;
		float: right;
		padding: 1rem;
		margin: 0 0 0.5rem 1rem;
		border-radius: 3px;
		/*background-color: #ffffdf;*/
		/*border: 2px solid #e8cfa9;*/
	}

	

}
